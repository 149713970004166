<template>
  <b-row class="match-height">
    <b-col
      lg="4"
      md="6">
      <b-card
        no-body
        class="card-apply-job"
        >
        <b-card-header class="pb-1">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                size="42"
                :src="require('@/assets/images/quotation/visibility.png')"
              />
            </b-media-aside>
            <b-media-body>
              <h5 class="mb-0">
                Higher Visibility
              </h5>
              <small class="text-muted">Updated 12m ago</small>
            </b-media-body>
          </b-media>
          <b-badge
            pill
            variant="light-primary"
          >
            Design
          </b-badge>
        </b-card-header>
        <b-card-body>
          <b-card-text class="mb-2 apply-job-desc">
            Affiliates constantly promote your products and services, drastically improving your website’s SEO.
          </b-card-text>

          <!-- payment  -->
          <div class="apply-job-package bg-light-primary rounded">
            <div>
              <sup class="text-body">
                <small>$</small>
              </sup>
              <h2 class="d-inline mr-25">
                9,800
              </h2>
              <sub class="text-body"><small>/ month</small></sub>
            </div>
            <b-badge
              variant="light-primary"
              pill
            >
              Full Time
            </b-badge>
          </div>
          <!--/ payment  -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
          >
            Find Out More!
          </b-button>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col
      lg="4"
      md="6">
      <b-card
        no-body
        class="card-apply-job"
        >
        <b-card-header class="pb-1">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                size="42"
                :src="require('@/assets/images/quotation/profits.png')"
              />
            </b-media-aside>
            <b-media-body>
              <h5 class="mb-0">
                More traffic
              </h5>
              <small class="text-muted">Updated 12m ago</small>
            </b-media-body>
          </b-media>
          <b-badge
            pill
            variant="light-primary"
          >
            Design
          </b-badge>
        </b-card-header>
        <b-card-body>
          <b-card-text class="mb-2 apply-job-desc">
            Higher visibility means more people will visit your website, and see your products and services.
          </b-card-text>

          <!-- payment  -->
          <div class="apply-job-package bg-light-primary rounded">
            <div>
              <sup class="text-body">
                <small>$</small>
              </sup>
              <h2 class="d-inline mr-25">
                9,800
              </h2>
              <sub class="text-body"><small>/ month</small></sub>
            </div>
            <b-badge
              variant="light-primary"
              pill
            >
              Full Time
            </b-badge>
          </div>
          <!--/ payment  -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
          >
            Find Out More!
          </b-button>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col
      lg="4"
      md="6">
      <b-card
        no-body
        class="card-apply-job"
        >
        <b-card-header class="pb-1">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                size="42"
                :src="require('@/assets/images/quotation/trolley.png')"
              />
            </b-media-aside>
            <b-media-body>
              <h5 class="mb-0">
                Increased sales
              </h5>
              <small class="text-muted">Updated 12m ago</small>
            </b-media-body>
          </b-media>
          <b-badge
            pill
            variant="light-primary"
          >
            Design
          </b-badge>
        </b-card-header>
        <b-card-body>
          <b-card-text class="mb-2 apply-job-desc">
            More traffic to your website means an increased likelihood of converting them into real customers.
          </b-card-text>

          <!-- payment  -->
          <div class="apply-job-package bg-light-primary rounded">
            <div>
              <sup class="text-body">
                <small>$</small>
              </sup>
              <h2 class="d-inline mr-25">
                9,800
              </h2>
              <sub class="text-body"><small>/ month</small></sub>
            </div>
            <b-badge
              variant="light-primary"
              pill
            >
              Full Time
            </b-badge>
          </div>
          <!--/ payment  -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
          >
            Find Out More!
          </b-button>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, BCardText, BCardBody, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
  },
  directives: {
    Ripple,
  },
}
</script>